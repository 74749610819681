import React, { useEffect, useState } from "react";
import Home from "./Home";
import "./styles.css"; 

const WebsiteHTML: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    // Initial check on mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>

      <section>
        <div>
          <Home />
        </div>
      </section>
    </>
  );
};

export default WebsiteHTML;
