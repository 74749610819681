import { useState, useEffect, useCallback, ReactNode } from "react";
import "./styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import Masonry from "react-masonry-css";
import { MapCard } from "components/Form/MapCard";
import { Card2 } from "components/Form/Card2";
import colors from "styles/colors";
import Heading from "components/Form/Heading";
import Modal from "components/Form/Modal";
import Footer from "components/misc/Footer";
import Nav from "components/Form/Nav";
import { RowProps } from "components/Form/Row";
import styles from "../components/onboarding_navbar/Navbar.module.css";
// import ProgressBa from "./progressbar";
import Loader from "components/misc/Loader";
import ErrorBoundary from "components/misc/ErrorBoundary";
// import SelfScanMsg from "components/misc/SelfScanMsg";
import DocContent from "components/misc/DocContent";
import ProgressBar, {
  LoadingJob,
  LoadingState,
  initialJobs,
} from "components/misc/ProgressBar";
import ActionButtons from "components/misc/ActionButtons";
// import AdditionalResources from "components/misc/AdditionalResources";
// import ViewRaw from "components/misc/ViewRaw";
import ViewHosts from "components/misc/ViewHosts";

import ServerLocationCard from "components/Results/ServerLocation";
import ServerInfoCard from "components/Results/ServerInfo";
import HostNamesCard from "components/Results/HostNames";
import WhoIsCard from "components/Results/WhoIs";
import LighthouseCard from "components/Results/Lighthouse";
import ScreenshotCard from "components/Results/Screenshot";
import SslCertCard from "components/Results/SslCert";
import HeadersCard from "components/Results/Headers";
import CookiesCard from "components/Results/Cookies";
import RobotsTxtCard from "components/Results/RobotsTxt";
import DnsRecordsCard from "components/Results/DnsRecords";
import RedirectsCard from "components/Results/Redirects";
import TxtRecordCard from "components/Results/TxtRecords";
import ServerStatusCard from "components/Results/ServerStatus";
import OpenPortsCard from "components/Results/OpenPorts";
import TraceRouteCard from "components/Results/TraceRoute";
import CarbonFootprintCard from "components/Results/CarbonFootprint";
import SiteFeaturesCard from "components/Results/SiteFeatures";
import DnsSecCard from "components/Results/DnsSec";
import HstsCard from "components/Results/Hsts";
import SitemapCard from "components/Results/Sitemap";
import DomainLookup from "components/Results/DomainLookup";
import DnsServerCard from "components/Results/DnsServer";
import TechStackCard from "components/Results/TechStack";
import SecurityTxtCard from "components/Results/SecurityTxt";
import ContentLinksCard from "components/Results/ContentLinks";
import SocialTagsCard from "components/Results/SocialTags";
import MailConfigCard from "components/Results/MailConfig";
import HttpSecurityCard from "components/Results/HttpSecurity";
import FirewallCard from "components/Results/Firewall";
import ArchivesCard from "components/Results/Archives";
import RankCard from "components/Results/Rank";
import BlockListsCard from "components/Results/BlockLists";
import ThreatsCard from "components/Results/Threats";
import TlsCipherSuitesCard from "components/Results/TlsCipherSuites";
import TlsIssueAnalysisCard from "components/Results/TlsIssueAnalysis";
import TlsClientSupportCard from "components/Results/TlsClientSupport";
import DeviceSummaryCard from "components/Results/DeviceSummary";
import UserLocationCard from "components/Results/UserLocation";
import ApiResultCard from "components/Results/ApiResultCard";

import keys from "utils/get-keys";
import { determineAddressType, AddressType } from "utils/address-type-checker";
import useMotherHook from "hooks/motherOfAllHooks";
import {
  getLocation,
  getUserLocation,
  ServerLocation,
  UserLocation,
  Cookie,
  applyWhoIsResults,
  Whois,
  parseShodanResults,
  ShodanResults,
} from "utils/result-processor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlassLocation,
  faServer,
  faLaptopCode,
  faGlobe,
  faLayerGroup,
  faRoute,
  faLock,
  faShield,
  faEnvelope,
  faTriangleExclamation,
  faAddressBook,
  faEarthAsia,
  faFileZipper,
  faCookieBite,
  faFile,
  faCloudArrowUp,
  faLink,
  faHeading,
  faFireFlameCurved,
  faStar,
  faAnchor,
  faRankingStar,
  faClipboardList,
  faMobileScreen,
  faEarthAfrica,
  faFileShield,
  faSearch,
  faRecordVinyl,
  faSquare,
  faPieChart,
  faFilter,
  faCode,
  faHandshake,
  faEyeSlash,
  faArrowRight,
  faTag,
} from "@fortawesome/free-solid-svg-icons";

import MapRoute from "components/Results/Maproutes";
// import FilterableCards from "./filterscard";
// import App from "./progressbar";
import ActionButtons2 from "components/misc/ActionButtons2";
import MetaTagsCard from "components/Results/MetaTags";
// import MetaCard from "components/Form/MetaCard";

const LoadingSpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh; /* Fullscreen height */
  flex-direction: column;
  color: #034772 !important;
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const LoadingSpinner = () => (
  <LoadingSpinnerWrapper>
    {/* <p>Loading, please wait...</p> */}
    <p>Just a moment!</p>
    <Spinner />
  </LoadingSpinnerWrapper>
);

const ResultsOuter = styled.div`
  display: flex;
  flex-direction: column;
  .masonry-grid {
    display: flex;
    width: auto;
  }
  .masonry-grid-col section {
    margin: 1rem 0.5rem;
  }
  @media (max-width: 768px) {
    overflow-x: hidden;
  }
`;

const ResultsContent = styled.section`
  width: 95vw;
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 1rem;
  margin: auto;
  width: calc(100% - 2rem);
  padding-bottom: 1rem;
`;

const FilterButtons = styled.div`
  width: 95vw;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  font-family: "Rajdhani";
  justify-content: space-between;
  background-color: "blue";
  gap: 1rem;
  .one-half {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    font-family: "Rajdhani";
  }
  .group-label {
    font-size: 14px;
  }
  @media (max-width: 480px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .control-options {
      justify-content: center !important;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem !important;
      align-items: center;
      a {
        text-decoration: none;
      }
    }
  }
  @media (max-width: 850px) {
    width: 80vw;
    justify-content: center;
  }
  @media (max-width: 1100px) {
    justify-content: center;
  }
  button,
  input,
  .toggle-filters {
    font-family: "Rajdhani";
    font-weight: bold;
    background: ${colors.bgShadowColor};
    color: ${colors.background};
    border: none;
    border-radius: 4px;
    padding: 0.5rem 0.5rem;
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;
  }
  button,
  .toggle-filters {
    cursor: pointer;
    text-transform: capitalize;
    // box-shadow: 2px 2px 0px ${colors.bgShadowColor};
    transition: all 0.2s ease-in-out;
    &:hover {
      // box-shadow: 4px 4px 0px ${colors.background};
      background: ${colors.background};
      color: ${colors.bgShadowColor};
      border: 1px solid ${colors.primary};
    }
    &.selected {
      color: ${colors.backgroundLighter};
      background: ${colors.bgShadowColor};
      font-size: 12px;
    }
    &:hover {
      border: 1px solid ${colors.backgroundLighter};
      color: ${colors.bgShadowColor};
      background: ${colors.backgroundLighter};
    }
    &.notselected {
      font-size: 12px;
    }
  }
  .filter {
    background-color: #034772;
    color: white;
    padding: 2px 10px;
    border: none;
    border-radius: 5px;
    width: 25vh;
    font-size: 12px;
    &::placeholder {
      color: white;
      font-size: 14px; // Set the placeholder color to white
    }
  }
  input:focus {
    border: 1px solid ${colors.backgroundLighter};
    outline: none;
  }
  .clear {
    color: ${colors.textColor};
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.8rem;
    opacity: 0.8;
  }
  .toggle-filters {
    font-size: 0.8rem;
  }
  .control-options {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    a {
      text-decoration: none;
    }
  }
`;

const NoDataMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #555;
  @media (max-width: 480px) {
    height: 30vh;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px); /* Add a light blur to the background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Make sure the overlay is on top */
`;

const AlertBox = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  // box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 10px 5px #075283;
  text-align: center;
  width: 35vw;
  @media (max-width: 480px) {
    max-width: 80vw !important;
  }
  @media (max-width: 1000px) {
    min-width: 40vw;
  }
  @media (max-width: 768px) {
    min-width: 60vw;
  }
`;

const CloseButton = styled.button`
  background-color: ${colors.bgShadowColor};
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: ${colors.textColorSecondar};
  }
`;

interface ResultsComponentProps {
  // results: any;
  scanId: string;
  domainName: string;
  // skipOnboarding: boolean;
}

const Results: React.FC<ResultsComponentProps> = ({
  // results,
  scanId,
  domainName,
  // skipOnboarding,
}): JSX.Element => {
  const startTime = new Date().getTime();
  const location = useLocation();
  const [address, setAddress] = useState<string | undefined>(domainName); // Initialize with domainName if available
  const [error, setError] = useState<string | null>(null);
  const [addressType, setAddressType] = useState<AddressType>("empt");
  const [loadingJobs, setLoadingJobs] = useState<LoadingJob[]>(initialJobs);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<ReactNode>(<></>);
  const [showFilters, setShowFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isToastVisible, setIsToastVisible] = useState<boolean>(false);

  const [mtrResult, setMtrResult] = useState<any>(null);
  const [data, setData] = useState<boolean>(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setData(true);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsToastVisible(true);
    }, 2500);
    return () => clearTimeout(timeoutId);
  }, []);
  // Function to fetch the address if it's not passed via state
  const fetchAddress = async (scanId: string) => {
    try {
      if (location.state?.domainName) {
        const response = await fetch(`/api/getDomainNameByScanId/${scanId}`);
        if (!response.ok) {
          throw new Error("Failed to fetch address");
        } else {
          const data = await response.json();
          setAddress(data.domainName); // Set default if domainName is null or undefined
          setLoading(false);
        }
      }
    } catch (error) {
      console.error("Failed to fetch the address:", error);
      setError("Failed to retrieve the address. Please try again later.");
      setLoading(false);
    }
  };

  const clearFilters = () => {
    setTags([]);
    setSearchTerm("");
  };
  // const updateTags = (tag: string) => {
  //   // Remove current tag if it exists, otherwise add it
  //   // setTags(tags.includes(tag) ? tags.filter(t => t !== tag) : [...tags, tag]);
  //   setTags(tags.includes(tag) ? tags.filter((t) => t !== tag) : [tag]);
  // };
  const updateTags = (tag: string) => {
    setTags((prevTags) => {
      return prevTags.includes(tag)
        ? prevTags.filter((t) => t !== tag) // Remove the tag
        : [...prevTags, tag];
    });
  };

  const updateLoadingJobs = useCallback(
    (
      jobs: string | string[],
      newState: LoadingState,
      error?: string,
      retry?: () => void,
      data?: any
    ) => {
      (typeof jobs === "string" ? [jobs] : jobs).forEach((job: string) => {
        const now = new Date();
        const timeTaken = now.getTime() - startTime;
        setLoadingJobs((prevJobs) => {
          const newJobs = prevJobs.map((loadingJob: LoadingJob) => {
            if (job.includes(loadingJob.name)) {
              return {
                ...loadingJob,
                error,
                state: newState,
                timeTaken,
                retry,
              };
            }
            return loadingJob;
          });

          // const timeString =
          //   `[${now.getHours().toString().padStart(2, "0")}:` +
          //   `${now.getMinutes().toString().padStart(2, "0")}:` +
          //   `${now.getSeconds().toString().padStart(2, "0")}]`;

          if (newState === "success") {
            if (!(window as any).webCheck) {
              (window as any).webCheck = {};
            }
            if (data) {
              (window as any).webCheck[job] = data;
            }
          }

          if (newState === "error") {
          }
          return newJobs;
        });
      });
    },
    [startTime]
  );

  const parseJson = (response: Response): Promise<any> => {
    return new Promise((resolve) => {
      response
        .json()
        .then((data) => resolve(data))
        .catch((error) =>
          resolve({
            error:
              `Failed to get a valid response 😢\n` +
              `This is likely due the target not exposing the required data, ` +
              `or limitations in how Netlify executes lambda functions, such as the 10-sec timeout.\n\n` +
              `Error info:\n${error}`,
          })
        );
    });
  };

  const urlTypeOnly = ["url"] as AddressType[]; // Many jobs only run with these address types

  const api = process.env.REACT_APP_API_ENDPOINT || "/api"; // Where is the API hosted?

  // Fetch and parse IP address for given URL
  const [ipAddress, setIpAddress] = useMotherHook({
    jobId: "get-ip",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/get-ip?url=${address}`)
        .then((res) => parseJson(res))
        .then((res) => res.ip),
  });

  useEffect(() => {
    if (!address && scanId) {
      // Fetch the address only if it's not already provided
      fetchAddress(scanId);
      // console.log("Address2: ", address);
    }
    // else {
    //   setLoading(false);
    // }
    if (!addressType || addressType === "empt") {
      setAddressType(determineAddressType(address || ""));
    }
    if (addressType === "ipV4" && address) {
      setIpAddress(address);
    }
  }, [scanId, addressType, setIpAddress, address]);

  // Get IP address location info
  const [locationResults, updateLocationResults] =
    useMotherHook<ServerLocation>({
      jobId: "location",
      updateLoadingJobs,
      addressInfo: {
        address: ipAddress,
        addressType: "ipV4",
        expectedAddressTypes: ["ipV4", "ipV6"],
      },
      fetchRequest: () =>
        fetch(`/api/ipinfo/${ipAddress}`)
          .then((res) => parseJson(res))
          .then((res) => getLocation(res)),
      // fetch(`https://ipapi.co/${ipAddress}/json/`)
      //   .then((res) => parseJson(res))
      //   .then((res) => getLocation(res)),
      // fetch(`http://ip-api.com/json/${ipAddress}`)
      //   .then((res) => parseJson(res))
      //   .then((res) => getLocation(res)),
    });

  // State to hold hostsData
  const [hostsData, setHostsData] = useState(null);
  // Get site host from theharvester
  const [hostsIpResults, updateHostsIpResults] = useMotherHook({
    jobId: "hostsip",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/theharvester?url=${address}`)
        .then((res) => parseJson(res))
        .then((res) => {
          if (res.Errors && res.Errors.length > 0) {
            return {
              error: `No data returned, because ${
                res.Errors[0].Message || "API lookup failed"
              }`,
            };
          }
          // Set hostsData state with the received data
          setHostsData(res.hosts);
          // console.log("res.hosts", res.hosts);
          // console.log("hostsData", hostsData);

          return res;
        }),
  });

  // Get user's browser IP address location info
  const [userLocationResults, updateUserLocationResults] =
    useMotherHook<UserLocation>({
      jobId: "userLocation",
      updateLoadingJobs,
      addressInfo: {
        address: ipAddress,
        addressType: "ipV4",
        expectedAddressTypes: ["ipV4", "ipV6"],
      },
      fetchRequest: () =>
        fetch(`/api/ipinfo`)
          .then((res) => parseJson(res))
          .then((res) => getUserLocation(res)),
      // fetch(`https://ipapi.co/json/`)
      //   .then((res) => parseJson(res))
      //   .then((res) => getUserLocation(res)),
      // fetch(`http://ip-api.com/json/`)
      //   .then((res) => parseJson(res))
      //   .then((res) => getUserLocation(res)),
    });

  // Get trace route for a given hostname
  const [traceRouteResults, updateTraceRouteResults] = useMotherHook({
    jobId: "trace-route",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: async () => {
      // Check if the MTR result is already present in localStorage
      const cachedResult = localStorage.getItem(`traceRouteResult_${address}`);

      if (cachedResult) {
        // If result is found in localStorage, parse and return it
        return JSON.parse(cachedResult);
      } else {
        // If result is not found, fetch from the API
        const response = await fetch(`${api}/trace-route?url=${address}`);
        const result = await parseJson(response);

        // Cache the result in localStorage for future use
        localStorage.setItem(
          `traceRouteResult_${address}`,
          JSON.stringify(result)
        );

        return result;
      }
    },
  });
  // Fetch and parse SSL certificate info
  const [sslResults, updateSslResults] = useMotherHook({
    jobId: "ssl",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/ssl?url=${address}`).then((res) => parseJson(res)),
  });

  // Run a manual whois lookup on the domain
  const [domainLookupResults, updateDomainLookupResults] = useMotherHook({
    jobId: "domain",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/whois?url=${address}`).then((res) => parseJson(res)),
  });

  // Fetch and parse Lighthouse performance data
  // const [lighthouseResults, updateLighthouseResults] = useMotherHook({
  //   jobId: "quality",
  //   updateLoadingJobs,
  //   addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
  //   fetchRequest: () =>
  //     fetch(`${api}/quality?url=${address}`)
  //       .then((res) => parseJson(res))
  //       .then((res) => res?.lighthouseResult || { error: "No Data" }),
  // });

  // Get the technologies used to build site, using Wappalyzer
  const [techStackResults, updateTechStackResults] = useMotherHook({
    jobId: "tech-stack",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/tech-stack?url=${address}`).then((res) => parseJson(res)),
  });

  // Get hostnames and associated domains from Shodan
  const [shoadnResults, updateShodanResults] = useMotherHook<ShodanResults>({
    jobId: ["hosts", "server-info"],
    updateLoadingJobs,
    addressInfo: {
      address: ipAddress,
      addressType: "ipV4",
      expectedAddressTypes: ["ipV4", "ipV6"],
    },
    fetchRequest: () =>
      // fetch(`https://api.shodan.io/shodan/host/${ipAddress}?key=${keys.shodan}`)
      fetch(`${api}/server-info?url=${ipAddress}`)
        .then((res) => parseJson(res))
        .then((res) => parseShodanResults(res)),
  });
  useEffect(() => {
    if (shoadnResults) {
      // console.log("Update Shodan results:", updateShodanResults);
    }
  }, [shoadnResults, updateShodanResults]);

  // Fetch and parse cookies info
  const [cookieResults, updateCookieResults] = useMotherHook<{
    cookies: Cookie[];
  }>({
    jobId: "cookies",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/cookies?url=${address}`).then((res) => parseJson(res)),
  });

  // Fetch and parse headers
  const [headersResults, updateHeadersResults] = useMotherHook({
    jobId: "headers",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/headers?url=${address}`).then((res) => parseJson(res)),
  });

  // Fetch and parse DNS records
  const [dnsResults, updateDnsResults] = useMotherHook({
    jobId: "dns",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/dns?url=${address}`).then((res) => parseJson(res)),
  });

  // Get HTTP security
  const [httpSecurityResults, updateHttpSecurityResults] = useMotherHook({
    jobId: "http-security",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/http-security?url=${address}`).then((res) =>
        parseJson(res)
      ),
  });

  // Get social media previews, from a sites social meta tags
  const [socialTagResults, updateSocialTagResults] = useMotherHook({
    jobId: "social-tags",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/social-tags?url=${address}`).then((res) => parseJson(res)),
  });

  const [securityTxtResults, updateSecurityTxtResults] = useMotherHook({
    jobId: "security-txt",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/security-txt?url=${address}`).then((res) => parseJson(res)),
  });

  // Get the DNS server(s) for a domain, and test DoH/DoT support
  const [dnsServerResults, updateDnsServerResults] = useMotherHook({
    jobId: "dns-server",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/dns-server?url=${address}`).then((res) => parseJson(res)),
  });

  // Get the WAF and Firewall info for a site
  const [firewallResults, updateFirewallResults] = useMotherHook({
    jobId: "firewall",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/firewall?url=${address}`).then((res) => parseJson(res)),
  });

  // Get DNSSEC info
  const [dnsSecResults, updateDnsSecResults] = useMotherHook({
    jobId: "dnssec",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/dnssec?url=${address}`).then((res) => parseJson(res)),
  });

  // Check if a site is on the HSTS preload list
  const [hstsResults, updateHstsResults] = useMotherHook({
    jobId: "hsts",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/hsts?url=${address}`).then((res) => parseJson(res)),
  });

  // Check if a host is present on the URLHaus malware list
  const [threatResults, updateThreatResults] = useMotherHook({
    jobId: "threats",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/threats?url=${address}`).then((res) => parseJson(res)),
  });

  // Get mail config for server, based on DNS records
  const [mailConfigResults, updateMailConfigResults] = useMotherHook({
    jobId: "mail-config",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/mail-config?url=${address}`).then((res) => parseJson(res)),
  });

  // Get list of archives from the Wayback Machine
  const [archivesResults, updateArchivesResults] = useMotherHook({
    jobId: "archives",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/archives?url=${address}`).then((res) => parseJson(res)),
  });

  // Get website's global ranking, from Tranco
  const [rankResults, updateRankResults] = useMotherHook({
    jobId: "rank",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/rank?url=${address}`).then((res) => parseJson(res)),
  });

  // Get Device Summary from cloudflare
  const [deviceSummaryResults, updateDeviceSummaryResults] = useMotherHook({
    jobId: "deviceSummary",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/device-summary?url=${address}`).then((res) =>
        parseJson(res)
      ),
  });

  // Take a screenshot of the website
  const [screenshotResult, updateScreenshotResult] = useMotherHook({
    jobId: "screenshot",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/screenshot?url=${address}`).then((res) => parseJson(res)),
  });

  // Get TLS security info, from Mozilla Observatory
  const [tlsResults, updateTlsResults] = useMotherHook({
    jobId: ["tls-cipher-suites", "tls-security-config", "tls-client-support"],
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/tls?url=${address}`).then((res) => parseJson(res)),
  });

  // Fetches URL redirects
  const [redirectResults, updateRedirectResults] = useMotherHook({
    jobId: "redirects",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/redirects?url=${address}`).then((res) => parseJson(res)),
  });

  // Get list of links included in the page content
  const [linkedPagesResults, updateLinkedPagesResults] = useMotherHook({
    jobId: "linked-pages",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/linked-pages?url=${address}`).then((res) => parseJson(res)),
  });

  // Fetch and parse crawl rules from robots.txt
  const [robotsTxtResults, updateRobotsTxtResults] = useMotherHook<{
    robots: RowProps[];
  }>({
    jobId: "robots-txt",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/robots-txt?url=${address}`).then((res) => parseJson(res)),
  });

  // Get current status and response time of server
  const [serverStatusResults, updateServerStatusResults] = useMotherHook({
    jobId: "status",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/status?url=${address}`).then((res) => parseJson(res)),
  });

  // Check for open ports
  const [portsResults, updatePortsResults] = useMotherHook({
    jobId: "ports",
    updateLoadingJobs,
    addressInfo: {
      address: ipAddress,
      addressType: "ipV4",
      expectedAddressTypes: ["ipV4", "ipV6"],
    },
    fetchRequest: () =>
      fetch(`${api}/ports?url=${ipAddress}`).then((res) => parseJson(res)),
  });

  // Fetch and parse domain whois results
  const [whoIsResults, updateWhoIsResults] = useMotherHook<
    Whois | { error: string }
  >({
    jobId: "whois",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(
        `https://api.whoapi.com/?domain=${address}&r=whois&apikey=${keys.whoApi}`
      )
        .then((res) => parseJson(res))
        .then((res) => applyWhoIsResults(res)),
  });

  // Fetches DNS TXT records
  const [txtRecordResults, updateTxtRecordResults] = useMotherHook({
    jobId: "txt-records",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/txt-records?url=${address}`).then((res) => parseJson(res)),
  });

  // Check site against DNS blocklists
  const [blockListsResults, updateBlockListsResults] = useMotherHook({
    jobId: "block-lists",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/block-lists?url=${address}`).then((res) => parseJson(res)),
  });

  // Get a websites listed pages, from sitemap
  const [sitemapResults, updateSitemapResults] = useMotherHook({
    jobId: "sitemap",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/sitemap?url=${address}`).then((res) => parseJson(res)),
  });

  // Fetch carbon footprint data for a given site
  const [carbonResults, updateCarbonResults] = useMotherHook({
    jobId: "carbon",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/carbon?url=${address}`).then((res) => parseJson(res)),
  });

  // Get site features from BuiltWith
  const [siteFeaturesResults, updateSiteFeaturesResults] = useMotherHook({
    jobId: "features",
    updateLoadingJobs,
    addressInfo: { address, addressType, expectedAddressTypes: urlTypeOnly },
    fetchRequest: () =>
      fetch(`${api}/features?url=${address}`)
        .then((res) => parseJson(res))
        .then((res) => {
          if (res.Errors && res.Errors.length > 0) {
            return {
              error: `No data returned, because ${
                res.Errors[0].Message || "API lookup failed"
              }`,
            };
          }
          return res;
        }),
  });

  /* Cancel remaining jobs after  10 second timeout */
  useEffect(() => {
    const checkJobs = () => {
      loadingJobs.forEach((job) => {
        if (job.state === "loading") {
          updateLoadingJobs(job.name, "timed-out");
        }
      });
    };
    const timeoutId = setTimeout(checkJobs, 800000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [loadingJobs, updateLoadingJobs]);

  const makeSiteName = (address: string): string => {
    try {
      return new URL(address).hostname.replace("www.", "");
    } catch (error) {
      return address;
    }
  };

  // A list of state sata, corresponding component and title for each card
  const resultCardData = [
    {
      id: "location",
      title: "Server Info",
      result: locationResults,
      Component: ServerLocationCard,
      refresh: updateLocationResults,
      tags: ["server"],
      icon: faServer,
    },
    {
      id: "userLocation",
      title: "User Info",
      result: userLocationResults,
      Component: UserLocationCard,
      refresh: updateUserLocationResults,
      tags: ["server"],
      icon: faMagnifyingGlassLocation,
    },
    {
      id: "metaTags",
      title: "Meta-Tags",
      result: domainLookupResults,
      Component: MetaTagsCard,
      refresh: updateDomainLookupResults,
      tags: ["server"],
      icon: faTag,
    },
    {
      id: "domain",
      title: "Domain Whois",
      result: domainLookupResults,
      Component: DomainLookup,
      refresh: updateDomainLookupResults,
      tags: ["server"],
      icon: faGlobe,
    },
    {
      id: "screenshot",
      title: "Screenshot",
      result: screenshotResult,
      // screenshotResult || lighthouseResults?.fullPageScreenshot?.screenshot,
      Component: ScreenshotCard,
      refresh: updateScreenshotResult,
      tags: ["client", "meta"],
      icon: faMobileScreen,
    },
    {
      id: "http-security",
      title: "HTTP Security",
      result: httpSecurityResults,
      Component: HttpSecurityCard,
      refresh: updateHttpSecurityResults,
      tags: ["security"],
      icon: faLaptopCode,
    },
    {
      id: "trace-route",
      title: "Trace Route",
      result: traceRouteResults,
      Component: TraceRouteCard,
      refresh: updateTraceRouteResults,
      tags: ["server"],
      icon: faRoute,
    },
    {
      id: "tech-stack",
      title: "Tech Stack",
      result: techStackResults,
      Component: TechStackCard,
      refresh: updateTechStackResults,
      tags: ["client", "meta"],
      icon: faLayerGroup,
    },
    {
      id: "dns",
      title: "DNS Records",
      result: dnsResults,
      Component: DnsRecordsCard,
      refresh: updateDnsResults,
      tags: ["server"],
      icon: faLock,
    },
    {
      id: "dns-server",
      title: "DNS Server",
      result: dnsServerResults,
      Component: DnsServerCard,
      refresh: updateDnsServerResults,
      tags: ["server"],
      icon: faGlobe,
    },
    {
      id: "hsts",
      title: "HSTS Check",
      result: hstsResults,
      Component: HstsCard,
      refresh: updateHstsResults,
      tags: ["security"],
      icon: faAnchor,
    },
    {
      id: "server-info",
      title: "Server Info",
      result: shoadnResults?.serverInfo,
      Component: ServerInfoCard,
      refresh: updateShodanResults,
      tags: ["server"],
      icon: faServer,
    },
    {
      id: "status",
      title: "Server Status",
      result: serverStatusResults,
      Component: ServerStatusCard,
      refresh: updateServerStatusResults,
      tags: ["server"],
      icon: faServer,
    },
    {
      id: "security-txt",
      title: "Security.Txt",
      result: securityTxtResults,
      Component: SecurityTxtCard,
      refresh: updateSecurityTxtResults,
      tags: ["security"],
      icon: faShield,
    },
    {
      id: "dnssec",
      title: "DNSSEC",
      result: dnsSecResults,
      Component: DnsSecCard,
      refresh: updateDnsSecResults,
      tags: ["security"],
      icon: faShield,
    },
    {
      id: "headers",
      title: "Headers",
      result: headersResults,
      Component: HeadersCard,
      refresh: updateHeadersResults,
      tags: ["client", "security"],
      icon: faHeading,
    },
    {
      id: "mail-config",
      title: "Email Configuration",
      result: mailConfigResults,
      Component: MailConfigCard,
      refresh: updateMailConfigResults,
      tags: ["server"],
      icon: faEnvelope,
    },
    {
      id: "block-lists",
      title: "Block Lists",
      result: blockListsResults,
      Component: BlockListsCard,
      refresh: updateBlockListsResults,
      tags: ["security", "meta"],
      icon: faSquare,
    },
    {
      id: "threats",
      title: "Threats",
      result: threatResults,
      Component: ThreatsCard,
      refresh: updateThreatResults,
      tags: ["security"],
      icon: faTriangleExclamation,
    },
    {
      id: "social-tags",
      title: "Social Tags",
      result: socialTagResults,
      Component: SocialTagsCard,
      refresh: updateSocialTagResults,
      tags: ["client", "meta"],
      icon: faAddressBook,
    },
    {
      id: "ports",
      title: "Open Ports",
      result: portsResults,
      Component: OpenPortsCard,
      refresh: updatePortsResults,
      tags: ["server"],
      icon: faSearch,
    },
    {
      id: "archives",
      title: "Archive History",
      result: archivesResults,
      Component: ArchivesCard,
      refresh: updateArchivesResults,
      tags: ["meta"],
      icon: faFileZipper,
    },
    {
      id: "cookies",
      title: "Cookies",
      result: cookieResults,
      Component: CookiesCard,
      refresh: updateCookieResults,
      tags: ["client", "security"],
      icon: faCookieBite,
    },
    {
      id: "robots-txt",
      title: "Crawl Rules",
      result: robotsTxtResults,
      Component: RobotsTxtCard,
      refresh: updateRobotsTxtResults,
      tags: ["meta"],
      icon: faFileShield,
    },
    {
      id: "sitemap",
      title: "Pages",
      result: sitemapResults,
      Component: SitemapCard,
      refresh: updateSitemapResults,
      tags: ["meta"],
      icon: faFile,
    },
    {
      id: "txt-records",
      title: "TXT Records",
      result: txtRecordResults,
      Component: TxtRecordCard,
      refresh: updateTxtRecordResults,
      tags: ["server"],
      icon: faRecordVinyl,
    },
    {
      id: "firewall",
      title: "Firewall",
      result: firewallResults,
      Component: FirewallCard,
      refresh: updateFirewallResults,
      tags: ["server", "security"],
      icon: faFireFlameCurved,
    },
    {
      id: "carbon",
      title: "Carbon Footprint",
      result: carbonResults,
      Component: CarbonFootprintCard,
      refresh: updateCarbonResults,
      tags: ["meta"],
      icon: faCloudArrowUp,
    },

    {
      id: "linked-pages",
      title: "Linked Pages",
      result: linkedPagesResults,
      Component: ContentLinksCard,
      refresh: updateLinkedPagesResults,
      tags: ["client", "meta"],
      icon: faLink,
    },
    {
      id: "ssl",
      title: "SSL Certificate",
      result: sslResults,
      Component: SslCertCard,
      refresh: updateSslResults,
      tags: ["server", "security"],
      icon: faShield,
    },

    // {
    //   id: "quality",
    //   title: "Quality Summary",
    //   result: lighthouseResults,
    //   Component: LighthouseCard,
    //   refresh: updateLighthouseResults,
    //   tags: ["client"],
    //   icon: faStar,
    // },
    {
      id: "redirects",
      title: "Redirect",
      result: redirectResults,
      Component: RedirectsCard,
      refresh: updateRedirectResults,
      tags: ["server"],
      icon: faServer,
    },
    {
      id: "hosts",
      title: "Host Names",
      result: shoadnResults?.hostnames,
      Component: HostNamesCard,
      refresh: updateShodanResults,
      tags: ["server"],
      icon: faServer,
    },

    {
      id: "features",
      title: "Site Features",
      result: siteFeaturesResults,
      Component: SiteFeaturesCard,
      refresh: updateSiteFeaturesResults,
      tags: ["meta"],
      icon: faRoute,
    },

    {
      id: "rank",
      title: "Global Ranking",
      result: rankResults,
      Component: RankCard,
      refresh: updateRankResults,
      tags: ["meta"],
      icon: faRankingStar,
    },
    {
      id: "device summary",
      title: "Summary",
      result: deviceSummaryResults,
      Component: DeviceSummaryCard,
      refresh: updateDeviceSummaryResults,
      tags: ["meta"],
      icon: faClipboardList,
    },
    {
      id: "whois",
      title: "Domain Info",
      result: whoIsResults,
      Component: WhoIsCard,
      refresh: updateWhoIsResults,
      tags: ["server"],
      icon: faGlobe,
    },

    {
      id: "hostsip",
      title: "Hosts IPs",
      result: hostsIpResults,
      Component: ApiResultCard,
      refresh: updateHostsIpResults,
      tags: ["server"],
      icon: faPieChart,
    },
    {
      id: "tls-security-config",
      title: "TLS Security Issues",
      result: tlsResults,
      Component: TlsIssueAnalysisCard,
      refresh: updateTlsResults,
      tags: ["security"],
      icon: faEarthAfrica,
    },
    {
      id: "tls-client-support",
      title: "TLS Handshake",
      result: tlsResults,
      Component: TlsClientSupportCard,
      refresh: updateTlsResults,
      tags: ["security"],
      icon: faHandshake,
    },
    {
      id: "tls-cipher-suites",
      title: "TLS Cipher Suites",
      result: tlsResults,
      Component: TlsCipherSuitesCard,
      refresh: updateTlsResults,
      tags: ["server", "security"],
      icon: faEarthAsia,
    },
  ];

  const makeActionButtons = (
    title: string,
    refresh: () => void,
    showInfo: (id: string) => void
  ): ReactNode => {
    const actions = [
      {
        label: `Info about ${title}`,
        onClick: showInfo,
        icon: <span style={{ fontSize: "1rem" }}>ⓘ</span>,
      },
      { label: `Re-fetch ${title} data`, onClick: refresh, icon: "↻" },
    ];
    return <ActionButtons actions={actions} />;
  };
  const makeActionButtons2 = (
    title: string,
    refresh: () => void,
    showInfo: (id: string) => void
  ): ReactNode => {
    const actions = [
      {
        label: `Info about ${title}`,
        onClick: showInfo,
        icon: <span style={{ fontSize: "1rem" }}>ⓘ</span>,
      },
      { label: `Re-fetch ${title} data`, onClick: refresh, icon: "↻" },
    ];
    return <ActionButtons2 actions={actions} />;
  };

  const showInfo = (id: string) => {
    setModalContent(DocContent(id));
    setModalOpen(true);
  };

  const showErrorModal = (content: ReactNode) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const firstCard = resultCardData[0];
  const secondCard = resultCardData[1];
  const thirdCard = resultCardData[2];
  const showFirstCard = firstCard.title
    .toLowerCase()
    .includes(searchTerm.toLowerCase());
  const showSecondCard = secondCard.title
    .toLowerCase()
    .includes(searchTerm.toLowerCase());
  const showThirdCard = thirdCard.title
    .toLowerCase()
    .includes(searchTerm.toLowerCase());

  const remainingCards = resultCardData.slice(3);

  useEffect(() => {
    const mtrResul = localStorage.getItem("mtrResult");
    if (mtrResul) {
      const parsedData = JSON.parse(mtrResul); // Assert the type
      setMtrResult(parsedData);
      // console.log(mtrResult);
    }
  }, []);

  // const SearchBar = () => {
  //   return (
  //     <SearchInputContainer>
  //       <SearchInput
  //         type="text"
  //         placeholder="Search.."
  //         className="filter"
  //         value={searchTerm}
  //         onChange={(e) => setSearchTerm(e.target.value)}
  //       />
  //       <SearchButton type="submit">
  //         <FontAwesomeIcon icon={faSearch} />
  //         {/* <SearchIcon className="fa fa-search"></SearchIcon> */}
  //       </SearchButton>
  //     </SearchInputContainer>
  //   );
  // };
  const filteredCards = remainingCards.filter(
    ({ title, result, tags: cardTags }) =>
      // Check if any selected tag is present in the card's tags
      (tags.length === 0 || cardTags.some((tag) => tags.includes(tag))) &&
      // Match the title with the search term
      title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      result &&
      !result.error
  );

  const calculateLoadingStatePercentages = (
    loadingJobs: LoadingJob[]
  ): Record<LoadingState | string, number> => {
    const totalJobs = loadingJobs.length;

    // Initialize count object
    const stateCount: Record<LoadingState, number> = {
      success: 0,
      loading: 0,
      skipped: 0,
      error: 0,
      "timed-out": 0,
    };

    // Count the number of each state
    loadingJobs.forEach((job) => {
      stateCount[job.state] += 1;
    });

    // Convert counts to percentages
    const statePercentage: Record<LoadingState, number> = {
      success: (stateCount["success"] / totalJobs) * 100,
      loading: (stateCount["loading"] / totalJobs) * 100,
      skipped: (stateCount["skipped"] / totalJobs) * 100,
      error: (stateCount["error"] / totalJobs) * 100,
      "timed-out": (stateCount["timed-out"] / totalJobs) * 100,
    };

    return statePercentage;
  };

  const percentage = calculateLoadingStatePercentages(loadingJobs);
  // console.log(percentage.error);
  const errorPercentage = percentage.error + percentage.skipped;
  const navigate = useNavigate();
  const [navigateTrue, setNavigateTrue] = useState(false);

  useEffect(() => {
    if (errorPercentage > 80) {
      setNavigateTrue(true);
      setTimeout(() => {
        navigate("/");
      }, 5000);

      // Disable scrolling when the alert box is visible
      document.body.style.overflow = "hidden";
    } else {
      // Enable scrolling again
      document.body.style.overflow = "auto";
    }
  }, [errorPercentage, navigate]);

  // Handle redirection when the close button is clicked
  const handleClose = () => {
    setAddress("");
    navigate("/");
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value); // This will correctly update the search term state
  };
  return (
    <>
      <ResultsOuter>
        {/* <Nav>
        {address && (
          <Heading color={colors.textColor} size="medium">
             {addressType === "url" && (
              <a href={address} target="_blank" rel="noopener noreferrer">
                <img
                  width="30px"
                  height="30px"
                  src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://${makeSiteName(
                    address
                  )}&size=64`}
                  alt=""
                />
              </a>
            )}
            {makeSiteName(address)}
          </Heading>
        )}
      </Nav> */}
        <Nav>
          {address && (
            <Heading color={colors.textColor} size="medium">
              <a href={address} target="_blank" rel="noopener noreferrer">
                <img
                  width="30px"
                  height="30px"
                  src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://${makeSiteName(
                    address
                  )}&size=64`}
                  alt=""
                  style={{ margin: "0.2rem 0.5rem 0 0" }}
                />
                {makeSiteName(address)}
              </a>
            </Heading>
          )}
        </Nav>

        <ProgressBar
          loadStatus={loadingJobs}
          showModal={showErrorModal}
          showJobDocs={showInfo}
          search={
            <div className="searchbox">
              <input
                type="text"
                placeholder="Title Search..."
                className="filter"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button className="searchbutton">
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
          }
          // search={<SearchBar></SearchBar>
          //   <div>
          //   <input
          //     type="text"
          //     placeholder="Filter Results"
          //     className="filter"
          //     value={searchTerm}
          //     onChange={(e) => setSearchTerm(e.target.value)}
          //   />
          // </div>
          // }
          filterButtons={
            <FilterButtons>
              {showFilters ? (
                <>
                  <div className="one-half">
                    <span className="group-label">Filter by</span>
                    {["server", "client", "meta"].map((tag: string) => (
                      <button
                        key={tag}
                        className={
                          tags.includes(tag) ? "selected" : "notselected"
                        }
                        onClick={() => updateTags(tag)}
                      >
                        <FontAwesomeIcon icon={faCode} /> {tag}
                      </button>
                    ))}
                    {(tags.length > 0 || searchTerm.length > 0) && (
                      <span onClick={clearFilters} className="clear">
                        Clear Filters
                      </span>
                    )}
                    <span
                      className="toggle-filters"
                      onClick={() => setShowFilters(false)}
                    >
                      <FontAwesomeIcon icon={faEyeSlash} /> Hide
                    </span>
                  </div>
                </>
              ) : (
                <div className="control-options">
                  <a>
                    <span
                      className="toggle-filters"
                      onClick={() => setShowFilters(true)}
                    >
                      <FontAwesomeIcon icon={faFilter} /> Show Filters
                    </span>
                  </a>
                  {/* <a href="#view-download-raw-data">
                      <span className="toggle-filters">
                        <FontAwesomeIcon icon={faDownload} /> Export Data
                      </span>
                    </a> */}
                  <a href="/about" target="_blank" rel="noopener noreferrer">
                    <span className="toggle-filters">
                      <FontAwesomeIcon icon={faCode} /> Learn about the Results
                    </span>
                  </a>
                  <a
                    href="/about#additional-resources"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="toggle-filters">
                      <FontAwesomeIcon icon={faLaptopCode} /> More tools
                    </span>
                  </a>
                  {/* <a href="https://github.com/lissy93/web-check"><span className="toggle-filters">View GitHub</span></a> */}
                </div>
              )}
            </FilterButtons>
          }
          value={searchTerm}
          onChange={handleSearchChange}
        />
        {/* {address?.includes(window?.location?.hostname || "x.redmorph.com") && (
        <SelfScanMsg />
      )} */}
        <Loader
          show={
            loadingJobs.filter((job: LoadingJob) => job.state !== "loading")
              .length < 5
          }
        />
        {data && locationResults !== undefined ? (
          <div style={{ margin: "0.5rem 0.5rem" }}>
            {(showFirstCard || showSecondCard) && (
              <Card2>
                <ErrorBoundary title={secondCard.title}>
                  <secondCard.Component
                    key={secondCard.id} // Use unique key
                    data={{ ...secondCard.result }}
                    title={secondCard.title}
                    icon={secondCard.icon}
                    actionButtons={
                      secondCard.refresh
                        ? makeActionButtons2(
                            secondCard.title,
                            secondCard.refresh,
                            () => showInfo(secondCard.id)
                          )
                        : undefined
                    }
                  />
                </ErrorBoundary>
                <div className={styles.verticalLine1}></div>
                <MapCard heading="Trace Route" icon={faRoute}>
                  {mtrResult ? (
                    <MapRoute mtrResult={mtrResult} loading={false} />
                  ) : (
                    <LoadingSpinner />
                  )}
                </MapCard>

                <div className={styles.verticalLine1}></div>
                <div style={{ right: 1 }}>
                  <ErrorBoundary title={firstCard.title}>
                    <firstCard.Component
                      key={firstCard.id} // Use unique key
                      data={{ ...firstCard.result }}
                      title={firstCard.title}
                      icon={firstCard.icon}
                      actionButtons={
                        firstCard.refresh
                          ? makeActionButtons2(
                              firstCard.title,
                              firstCard.refresh,
                              () => showInfo(firstCard.id)
                            )
                          : undefined
                      }
                    />
                  </ErrorBoundary>
                </div>
              </Card2>
            )}
          </div>
        ) : null}

        {data && domainLookupResults !== undefined ? (
          <MetaTagsCard
            key={thirdCard.id} // Use unique key
            data={{ ...thirdCard.result }}
            title={thirdCard.title}
            icon={thirdCard.icon}
            actionButtons={
              thirdCard.refresh
                ? makeActionButtons2(thirdCard.title, thirdCard.refresh, () =>
                    showInfo(thirdCard.id)
                  )
                : undefined
            }
          />
        ) : null}

        <ResultsContent>
          {filteredCards.length === 0 ? (
            // Show "No data available" if no card matches the search term
            <NoDataMessage>No data available</NoDataMessage>
          ) : (
            <Masonry
              breakpointCols={{
                10000: 12,
                4000: 9,
                3600: 8,
                3200: 7,
                2800: 6,
                2400: 5,
                2000: 4,
                1800: 3,
                1200: 2,
                800: 1,
                400: 1,
              }}
              className="masonry-grid"
              columnClassName="masonry-grid-col"
            >
              {filteredCards.map(
                (
                  { id, title, result, tags, refresh, Component, icon },
                  index: number
                ) => {
                  const show =
                    (tags.length === 0 ||
                      tags.some((tag) => tags.includes(tag))) &&
                    title.toLowerCase().includes(searchTerm.toLowerCase()) &&
                    result &&
                    !result.error;
                  return show ? (
                    <ErrorBoundary
                      title={title ? title : title.toString()}
                      key={id}
                    >
                      <Component
                        key={`${title}-${index}`}
                        data={{ ...result }}
                        title={title}
                        icon={icon}
                        actionButtons={
                          refresh
                            ? makeActionButtons(title, refresh, () =>
                                showInfo(id)
                              )
                            : undefined
                        }
                      />
                    </ErrorBoundary>
                  ) : null;
                }
              )}
            </Masonry>
          )}
        </ResultsContent>
        <div>{hostsData && <ViewHosts hosts={hostsData} />}</div>
        {/* <ViewRaw everything={resultCardData} /> */}
        {/* <AdditionalResources url={address} /> */}
        <Footer />
        <Modal isOpen={modalOpen} closeModal={() => setModalOpen(false)}>
          {modalContent}
        </Modal>
        <ToastContainer
          limit={2}
          draggablePercent={60}
          autoClose={1200}
          theme="dark"
          position="bottom-right"
        />
      </ResultsOuter>
      {navigateTrue && (
        <Overlay>
          <AlertBox>
            <h3>Oops!</h3>
            <p>
              This domain doesn’t have enough data. Let’s take you back to the
              homepage.
            </p>
            <CloseButton onClick={handleClose}>Close</CloseButton>
          </AlertBox>
        </Overlay>
      )}
    </>
  );
};

export default Results;
