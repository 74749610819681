import colors from "styles/colors";
import styled from "styled-components";
import ErrorBoundary from "components/misc/ErrorBoundary";
import { ReactNode } from "react";
import Heading3 from "components/Form/Heading3";
import "./MetaTags.css";

const cardStyles = `
span.val {
  &.up { color: ${colors.success}; }
  &.down { color: ${colors.danger}; }
}
`;

const MetaTagsCard = (props: {
  data: any;
  title: string;
  actionButtons: any;
  icon: any;
}): JSX.Element => {
  const metaTags = props.data.metaTags || {};
  const isEmpty = Object.keys(metaTags).length === 0;

  // Check if metaTags is empty and skip rendering the card if true
  if (isEmpty) {
    return <></>;
  }

  return (
    <Card
      heading={props.title}
      actionButtons={props.actionButtons}
      styles={cardStyles}
      icon={props.icon}
    >
      {metaTags.description && (
        <Row lbl="Description" val={metaTags.description} />
      )}
      {metaTags.keywords && <Row lbl="Key-words" val={metaTags.keywords} />}
      {metaTags["article:tag"] && (
        <Row lbl="article:tag" val={metaTags["article:tag"]} />
      )}
      {metaTags.author && <Row lbl="Author" val={metaTags.author} />}
      {metaTags["meta-section"] && (
        <Row lbl="meta-section" val={metaTags["meta-section"]} />
      )}
      {metaTags["fb:app_id"] && (
        <Row lbl="fb:app_id" val={metaTags["fb:app_id"]} />
      )}
      {metaTags["og:type"] && <Row lbl="og:type" val={metaTags["og:type"]} />}
      {metaTags["viewport"] && (
        <Row lbl="viewport" val={metaTags["viewport"]} />
      )}
      {metaTags["article:publisher"] && (
        <Row lbl="article:publisher" val={metaTags["article:publisher"]} />
      )}
      {metaTags["og:description"] && (
        <Row lbl="og:description" val={metaTags["og:description"]} />
      )}
      {metaTags["og:site_name"] && (
        <Row lbl="og:site_name" val={metaTags["og:site_name"]} />
      )}
      {metaTags["og:title"] && (
        <Row lbl="og:title" val={metaTags["og:title"]} />
      )}
      {metaTags["og:type"] && <Row lbl="og:type" val={metaTags["og:type"]} />}
      {metaTags["og:url"] && <Row lbl="og:url" val={metaTags["og:url"]} />}
      {metaTags["template_type"] && (
        <Row lbl="template_type" val={metaTags["template_type"]} />
      )}
      {metaTags["twitter:card"] && (
        <Row lbl="twitter:card" val={metaTags["twitter:card"]} />
      )}
      {metaTags["twitter:image"] && (
        <Row lbl="twitter:image" val={metaTags["twitter:image"]} />
      )}
      {metaTags["twitter:description"] && (
        <Row lbl="twitter:description" val={metaTags["twitter:description"]} />
      )}
      {metaTags["twitter:site"] && (
        <Row lbl="twitter:site" val={metaTags["twitter:site"]} />
      )}
      {metaTags["twitter:title"] && (
        <Row lbl="twitter:title" val={metaTags["twitter:title"]} />
      )}
      {metaTags["type"] && <Row lbl="type" val={metaTags["type"]} />}
    </Card>
  );
};

export default MetaTagsCard;

export const StyledCard = styled.section<{ $styles?: string }>`
  background: ${colors.backgroundLighter};
  overflow: hidden;
  border: 1.5px solid;
  border-radius: 8px 8px 0 8px;
  border-color: ${colors.bgShadowColor};
  padding: 0.5rem 1rem 1rem;
  position: relative;
  margin: 0.5rem 1.5rem;
  max-height: 24rem;
  overflow: auto;
  font-family: "Rajdhani";
  font-size: 14px;
  ${(props) => props.$styles}
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 430px) {
    max-width: 89vw;
  }
  @media (max-width: 370px) {
    display: flex;
    flex-direction: column;
    max-width: 85vw;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const StyledHr = styled.hr`
  border: none;
  border-top: 2px solid #ff8b00;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  margin-right: -1rem;
`;

interface CardProps {
  children: React.ReactNode;
  heading?: string;
  styles?: string;
  actionButtons?: ReactNode | undefined;
  icon?: any;
}

const Card = (props: CardProps): JSX.Element => {
  const { children, heading, styles, actionButtons, icon } = props;
  return (
    <ErrorBoundary title={heading}>
      <StyledCard $styles={styles}>
        {heading && (
          <>
            <Heading3
              className="inner-heading"
              as="h4"
              color={colors.primary}
              icon={icon}
              actionbuttons={actionButtons}
            >
              {heading}
            </Heading3>
            <StyledHr />
          </>
        )}
        {children}
      </StyledCard>
    </ErrorBoundary>
  );
};

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

const Row = (props: RowProps) => {
  const { lbl, val } = props;
  return (
    <StyledRow key={`${lbl}-${val}`}>
      {lbl && <span className="lbl">{lbl}</span>}
      <span
        className="val"
        title={typeof val === "string" ? val : val?.toString()}
        onClick={() => copyToClipboard(val)}
      >
        {val}
      </span>
    </StyledRow>
  );
};

interface RowProps {
  lbl?: string;
  val?: any;
  key?: string | number;
  rowList?: RowProps[];
  title?: any;
  open?: boolean;
  plaintext?: string;
  listResults?: string[];
  children?: React.ReactNode;
}

export const StyledRow = styled.div`
  display: flex;
  justify-content: start;
  gap: 2rem;
  padding: 0.25rem;
  font-size: 14px;

  span.lbl {
    font-weight: bold;
    font-size: 14px;
    min-width: 10vw;
  }
  span.val {
    max-width: 89vw;
    font-size: 14px;
    a {
      color: ${colors.primary};
    }
  }
  @media (max-width: 480px) {
  }
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: space-between;
    gap: 0;
    span.lbl {
      min-width: 20vw;
    }
    span.val {
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      a {
        color: ${colors.primary};
      }
    }
  }
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: start;
  gap: 1rem;
  margin: 5px 0;
`;

const Label = styled.div`
  font-weight: bold;
  color: #555;
  margin-bottom: 4px;
  min-width: 5vw;
`;

const Value = styled.div`
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
// import colors from 'styles/colors';
// import styled from 'styled-components';
// import ErrorBoundary from 'components/misc/ErrorBoundary';
// // import Heading from 'components/Form/Heading';
// import { ReactNode } from 'react';
// import Heading3 from 'components/Form/Heading3';
// import './MetaTags.css';
// const cardStyles = `
// span.val {
//   &.up { color: ${colors.success}; }
//   &.down { color: ${colors.danger}; }
// }
// `;

// const MetaTagsCard = (props: { data: any, title: string, actionButtons: any, icon:any }): JSX.Element => {
// //   const domain = props.data.internicData || {};
//   const metaTags = props.data.metaTags || {};
//   const isEmpty = Object.keys(metaTags).length === 0;

//   return (

//     <div>
//     {
//       isEmpty ? (
//         <Card heading={props.title} actionButtons={props.actionButtons} styles={cardStyles} icon={props.icon}><p>No data available!</p></Card> // Display error message from backend
//       ) :
//     (<Card heading={props.title} actionButtons={props.actionButtons} styles={cardStyles} icon={props.icon}>
//       { metaTags.description && <Row lbl="Description" val={metaTags.description} /> }
//       { metaTags.keywords && <Row lbl="Key-words" val={metaTags.keywords} /> }

//       { metaTags["article:tag"] && <Row lbl="article:tag" val={metaTags["article:tag"]} /> }
//       { metaTags.author && <Row lbl="Author" val={metaTags.author} /> }
//       { metaTags["meta-section"] && <Row lbl="meta-section" val={metaTags["meta-section"]} /> }
//       { metaTags["fb:app_id"] && <Row lbl="fb:app_id" val={metaTags["fb:app_id"]} /> }
//       { metaTags["og:type"] && <Row lbl="og:type" val={metaTags["og:type"]} /> }
//       { metaTags["viewport"] && <Row lbl="viewport" val={metaTags["viewport"]} /> }
//       { metaTags["article:publisher"] && <Row lbl="article:publisher" val={metaTags["article:publisher"]} /> }
//       { metaTags["og:description"] && <Row lbl="og:description" val={metaTags["og:description"]} /> }
//       { metaTags["og:site_name"] && <Row lbl="og:site_name" val={metaTags["og:site_name"]} /> }
//       { metaTags["og:title"] && <Row lbl="og:title" val={metaTags["og:title"]} /> }
//       { metaTags["og:type"] && <Row lbl="og:type" val={metaTags["og:type"]} /> }
//       { metaTags["og:url"] && <Row lbl="og:url" val={metaTags["og:url"]} /> }
//       { metaTags["template_type"] && <Row lbl="template_type" val={metaTags["template_type"]} /> }
//       { metaTags["twitter:card"] && <Row lbl="twitter:card" val={metaTags["twitter:card"]} /> }
//       { metaTags["twitter:image"] && <Row lbl="twitter:image" val={metaTags["twitter:image"]} /> }
//       { metaTags["twitter:description"] && <Row lbl="twitter:description" val={metaTags["twitter:description"]} /> }
//       { metaTags["twitter:site"] && <Row lbl="twitter:site" val={metaTags["twitter:site"]} /> }
//       { metaTags["twitter:title"] && <Row lbl="twitter:title" val={metaTags["twitter:title"]} /> }
//       { metaTags["type"] && <Row lbl="type" val={metaTags["type"]} /> }

//       {/* <div className='gridItems'> */}
//       {/* { metaTags["meta-section"] && <RowContainer><Label>Meta-Section</Label><Value>{metaTags["meta-section"]}</Value></RowContainer>}
//       { metaTags["og:description"] && <RowContainer><Label>og-description</Label><Value>{metaTags["og:description"]}</Value></RowContainer>}
//       { metaTags["fb:app_id"] && <RowContainer><Label>fb:app_id</Label><Value>{metaTags["fb:app_id"]}</Value></RowContainer>}
//       { metaTags["og:type"] && <RowContainer><Label>og:type</Label><Value>{ metaTags["og:type"]}</Value></RowContainer>}
//       { metaTags.viewport && <RowContainer><Label>viewport</Label><Value>{metaTags["viewport"]}</Value></RowContainer>}
//       { metaTags["article:publisher"] && <RowContainer><Label>
//         article:publisher </Label><Value>{metaTags["article:publisher"]}</Value></RowContainer>}
//       { metaTags["og:description"] && <RowContainer><Label>og-description</Label><Value>{metaTags["og:description"]}</Value></RowContainer>}
//  */}

//       {/* </div> */}
//     </Card>)
//       }
//       </div>
//   );
// }

// export default MetaTagsCard;

// export const StyledCard = styled.section<{ $styles?: string}>`
//   background: ${colors.backgroundLighter};
//   overflow:hidden;
//   border: 1.5px solid;
//   border-radius: 8px 8px 0 8px;
//   border-color: ${colors.bgShadowColor};
//   padding: 0.5rem 1rem 1rem;
//   position: relative;
//   margin: 0.5rem 1.5rem;
//   max-height: 24rem;
//   overflow: auto;
//   font-family: 'Rajdhani';
//   font-size: 14px;
//   // font-family: 'Orbitron';
//   ${props => props.$styles}
//     @media (max-width: 480px) {
//   display: flex;
//   flex-direction: column;
// }
//   @media (max-width: 430px) {

//     max-width:89vw;
//   }
//       @media (max-width: 370px) {
//     display: flex;
//     flex-direction: column;
//     max-width:85vw;
//     /* Add any other desired styles for smaller screens */
//   }
// @media (max-width: 768px) {
//   display: flex;
//   flex-direction: column;
// }
// `;

// const StyledHr = styled.hr`
//   border: none;
//   border-top: 2px solid #FF8B00; // Customize thickness and color
//   margin-top: 0.5rem;
//   margin-bottom: 0.5rem;
//   width: calc(100% + 2rem); // Adjust to account for padding
//   margin-left: -1rem; // Offset padding on the left
//   margin-right: -1rem; // Offset padding on the right
// `;

// interface CardProps {
//   children: React.ReactNode;
//   heading?: string,
//   styles?: string;
//   actionButtons?: ReactNode | undefined;
//   icon?: any;
// };

// const Card = (props: CardProps): JSX.Element => {
//   const { children, heading, styles, actionButtons,icon } = props;
//   return (
// <ErrorBoundary title={heading}>
//       <StyledCard $styles={styles}>
//         {/* { actionButtons && actionButtons } */}
//         { heading &&
//         <>
//         <Heading3 className="inner-heading" as="h4" color={colors.primary} icon={icon} actionbuttons={actionButtons}>{heading}</Heading3>
//         <StyledHr />
//         </> }
//         {children}
//       </StyledCard>
//     </ErrorBoundary>
//   );
// }

//   const copyToClipboard = (text: string) => {
//     navigator.clipboard.writeText(text);
//   }

// const Row = (props: RowProps) => {
//     const { lbl, val } = props;
//     return (
//     <StyledRow key={`${lbl}-${val}`}>
//       { lbl && <span className="lbl" >{lbl}</span> }
//       <span className="val" title={ typeof val === 'string' ? val : val?.toString() } onClick={() => copyToClipboard(val)}>
//        {val}
//       </span>
//     </StyledRow>
//     );
//   };

//   interface RowProps {
//     lbl?: string,
//     val?: any,
//     key?: string | number,
//     rowList?: RowProps[],
//     // title?: string | number | boolean,
//     title?:any,
//     open?: boolean,
//     plaintext?: string,
//     listResults?: string[],
//     children?: React.ReactNode
//   }

//   export const StyledRow = styled.div`
//     display: flex;
//     justify-content: start;
//     gap:2rem;
//     // flex-wrap: wrap;
//     padding: 0.25rem;
//     font-size: 14px;
//     // &:not(:last-child) { border-bottom: 1px solid ${colors.primary}; }

//     span.lbl {
//     font-weight: bold; font-size: 14px;
//     min-width: 10vw;
//     }
//     span.val {

//       max-width: 89vw;

//       font-size: 14px;
//       a {
//         color: ${colors.primary};
//       }
//     }
//       @media (max-width: 480px) {

// }
//        @media (max-width: 768px) {
//        flex-direction: column;
//         justify-content: space-between;
//         gap:0;
//           span.lbl {
//     min-width: 20vw;
//     }
//      span.val {

//       overflow: hidden;
//       text-overflow: ;
//       font-size: 14px;
//       a {
//         color: ${colors.primary};
//       }
//     }
// }
//   `;

//   const RowContainer = styled.div`
//   display: flex;
//   justify-content: start;
//   gap:1rem;
//   margin: 5px 0;
//   `;

// const Label = styled.div`
// font-weight: bold;
// color: #555;
// margin-bottom: 4px;
// min-width: 5vw;
// `;

// const Value = styled.div`
// color: #333;
// overflow: hidden;
// text-overflow: ellipsis;
// white-space: nowrap;
// `;
